.main-container {
    background: white;
    flex: 1 1 auto;
    // margin-top: 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    //flex: 1 1 auto;
    overflow: auto;
    // padding: 20px;
    // border-radius: 60px;
    background-color: rgba(242, 243, 245, 1);

    .header {
        display: flex;
        position: relative;
        width: 100%;
        height: 60px;
        background-color: white;
        flex-direction: row;
        padding: 3.5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

        &-image {
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 26.5px;
        }

        &-title {
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 5px;
            margin-right: 113px;
        }

        &-input {
            display: flex;
            align-items: center;
            width: 354px;
            color: '#484848';
        }

        &-right-area {
            width: 247px;
            width: 247px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: end;
            margin-right: 30px;

            .user-drop-down {
                cursor: pointer;
            }

            .user-drop-down:hover {
                cursor: pointer;
            }
        }
    }

    .title {
        font-size: 18px;
        height: 30px;
        flex-shrink: 0;
        margin-left: 50px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bolder;
        color: #171718;
    }

    .button-con {
        flex-direction: row;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 50px;
        margin-right: 50px;

        .search-form {

            .search-form-item {
                width: 50% !important;
                margin-right: 20px;
            }
        }

        .button {

            .sup2 {
                vertical-align: super;
                font-size: smaller;
                color: red;
                margin-top: -5px;
            }
        }

    }

    .text {
        margin-left: 50px;
        //background-color: aqua;
        height: 60px;

        .text-list {
            font-size: 100px;
        }
    }

    .supplier-list {
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 1920px;
        margin-top: 10px;
        height: 100%;

        //margin-left: 277px;
        //flex-direction: row;
        //display: flex;
        // overflow: hidden;
        //border: 0.5px solid black;
        .list-content {
            // width: 250px;
            height: 100%;
            margin: 0 277px;
            align-items: center;
            text-align: center;
            flex: 1 1 auto;
            overflow: auto;

            .table-row {
                //border-bottom: 20px solid red;
                height: 56px;
                border-collapse: separate;
                border-spacing: 0px 10px;
                //border: 10px,blue; 
                //flex: 1;
                //cursor: pointer;
            }

            .content-company-name {
                font-size: 20px;
                text-align: center;
                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
                //margin-top: 100px;
            }

            .content-status {
                font-size: 16px;
                text-align: center;
                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
            }

            .content-company-address {
                font-size: 16px;
                text-align: center;
                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
            }

            .content-person-phone {
                font-size: 16px;
                text-align: center;
                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;

            }



        }

        .ant-table-tbody>tr>td {
            border-bottom: 4px solid #f0f0f0;
            //font-size: 12px;
            //color: green;
            border-right: none;
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
            display: none;
            font-size: 13px;
        }

        .ant-table {
            border-spacing: 0 10px;
        }

        .ant-table-header {
            background-color: red;

            .ant-table-cell {
                font-size: 14px;
                background-color: rgba(242, 243, 245, 1);
                color: #a9a9a9;
                font-weight: bold;
            }
        }

        .ant-table-cell {
            font-size: 14;
            // height: 39px;
        }

        .supplier {
            flex-direction: row;
            height: 100%;
            height: 60px;

            .item {
                margin-top: 20px;
                float: left;
                width: 25%;
                font-size: 15px;
                height: 20px;
                line-height: 20px;
            }
        }
    }

    .ant-table-cell {
        height: 100%;
        line-height: 30px;
        padding: 5px 16px;
    }

    .ant-form-item {
        height: 100%;
        margin: 0;
    }

    .ant-pagination {
        height: 48px;
        flex-shrink: 0;
        margin: 10px 0px 0px 0px !important;
    }

    .ant-divider {
        height: 100%;
        margin: 0;
        border-top: 2px solid #dcdee0;
    }

    .ant-pagination-item-active {
        background-color: #0081cc;

        a {
            color: white;
        }
    }

    .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ant-table {
                    overflow: auto;

                    &::-webkit-scrollbar {
                        height: 100%;
                        width: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        height: 100%;
                        width: 10px;
                        // height: 212px;
                        background: #DDE4EC;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    .ant-input-affix-wrapper {
        border-radius: 8px;
        align-items: center;
    }

    .ant-checkbox-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: "Hiragino Sans GB", sans-serif;
    }

    .ant-checkbox-inner {
        order: 2;
        font-family: "Hiragino Sans GB", sans-serif;
        font-weight: bold;
        border: 1px solid gray;
        box-sizing: border-box;
        outline: none;
    }

    .ant-checkbox+span {
        order: 1;
        margin-top: 5px;
        font-family: "Hiragino Sans GB", sans-serif;
        font-weight: bold;
        font-size: 15px;
        border-color: gray;
    }

    .ant-table-thead>tr>th {
        border: none;
        /* 去除表头边框 */
        text-align: center;
        /* 文字居中对齐 */
        color: red;
    }

    // .ant-pagination {
    //     position: fixed;
    //     bottom: 10px;
    //     // width: 100%;
    //     right: 0;
    //     // background-color: #fff;
    //     padding: 10px;
    //     z-index: 999;
    //     padding-right: 277px;
    // }

    .custom-pagination .ant-pagination-options {
        bottom: auto;
        top: 100%;
    }

    .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ant-table-body {
                    &::-webkit-scrollbar {
                        width: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        width: 10px;
                        // height: 212px;
                        background: #DDE4EC;
                        border-radius: 5px;
                    }
                }
            }
        }
    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;