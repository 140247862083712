.goods-topLine{
    // border: black 1px solid;
    height: 80px;
    //background-color: rgb(209, 209, 209);
    margin: 26px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .titleBox{
        border-left: #1271FF solid 2px;
        padding: 0 15px;
        background-color: #ECF3FA;

        .title{
            color: #000;
            font-family: 'Hiragino Sans GB', sans-serif;
            font-size: 24px;

        }
    }
    .filterBox{
        width: 555px;
        height: 47px;
        border-radius: 4px;
        background: #FFF;
        box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        // padding: 0 45px;

        .filterTxt{
            color: #191515;
            font-family: 'Hiragino Sans GB', sans-serif;
            font-size: 20px;
        }
        .filterItem{
            // border: #000 solid 1px;
            height: 100%;
            vertical-align:bottom;
            

            display: flex;
            flex-direction: row;
            align-items: center;
            
            border-bottom: #1271ff00 solid 2px;

            .countTxt{
                color: #A9A9A9;
                font-family: 'Hiragino Sans GB', sans-serif;
                font-size: 12px;
                
            }
        }
        .filterItem:hover{
            border-bottom: #1271FF solid 2px;
            cursor: pointer;
        }
        .filterItemChosen{
            border-bottom: #1271FF solid 2px;
        }
    }
    .releaseBox{
        width: 132px;
        height: 47px;

        .release{
            width: 132px;
            height: 47px;
            flex-shrink: 0;
            border-radius: 4px;
            background: #1271FF;
            box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

            span{
                color: #FFF;
                font-family: 'Hiragino Sans GB', sans-serif;
                font-size: 20px;
                font-weight:600;
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;