@import '~antd/dist/antd.less';

.tn-base {
    position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 100%;
    // background:#DEF3F4;
    // z-index: 99;
    // overflow-x: auto;

    .table-row {
        cursor: pointer;
    }

    .ant-table-thead>tr>th {
        padding: 4px 0px;
        background: #eef1f6;
        font-size: 14px;
    }

    .ant-table-tbody>tr>td {
        padding: 2px 0px;
    }
}

.fill-remaining-space {
    flex: 1 1 auto;
}

.ellipsis {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.ant-modal-body {
    padding: 0;
}

.ant-table-body {
    overflow: auto !important;
}
// .App {
//   text-align: center;
// }

// .App-logo {
//   height: 40vmin;
//   pointer-events: none;
// }

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;