.download-page-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        border: 0;
        color: inherit;
        font: inherit;
        font-size: 4rem;
        padding: 0;
        vertical-align: baseline;
    }

    .compony {
        border: 0;
        color: inherit;
        font: inherit;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        color: #01875f;
    }

    .btn {
        width: 200px;
        height: 48px;
        margin-bottom: 20px;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 18px;
    }

    .mobile-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 14px;
        color: #1f2329;
        background-color: #fff;
        border-radius: 6px;

        &.mobile-input-error {
            .mobile-input-left {
                border-color: #f54a45 #d0d3d6 #f54a45 #f54a45;
            }

            .mobile-input-right {
                border-color: #f54a45 #f54a45 #f54a45 #d0d3d6;
            }
        }

        .mobile-input-left {
            display: flex;
            flex-shrink: 1;
            align-items: center;
            justify-content: center;
            // min-width: 80px;
            height: 40px;
            padding: 0 8px;
            border: 1px solid #d0d3d6;
            border-radius: 6px 0 0 6px;

            .mobile-input-code {
                width: 58px;
                min-width: 0;
                color: #1f2329;
                text-align: center;
                white-space: nowrap;
                height: 24px;
                font-size: 16px;
                line-height: 24px;
                background-color: transparent;
                padding: 1px 2px;
            }

            .mobile-input-dropdown-icon {
                display: inline-block;
                width: 16px;
                height: 16px;
                font-size: 16px;
                color: #646a73;
                cursor: pointer;
            }
        }

        .mobile-input-right {
            display: flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
            height: 40px;
            border: 1px solid #d0d3d6;
            border-left-color: transparent;
            border-radius: 0 6px 6px 0;
            transform: translateX(-1px);
            font-size: 16px;

            .mobile-input-phone {
                width: 100%;
                padding: 0 10px;
                margin-right: 1px;
                overflow: hidden;
                height: 24px;
                font-size: 16px;
                line-height: 24px;
                background: transparent;
                outline: none;
            }
        }
    }

    .code-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 16px;
        color: #1f2329;

        .code-box-input {
            width: 40px;
            height: 40px;
            padding: 0;
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
            color: #1f2329;
            text-align: center;
            background-color: #fff;
            border: 1px solid #d0d3d6;
            border-radius: 6px;
            outline: none;

            &:focus {
                border: 1px solid #3B80C3;
            }

            &-error {
                border-color: #f54a45;
            }
        }

        .code-box-input-seg-line {
            width: 11px;
            height: 1px;
            background: #1f2329;
        }
    }

    .code-resend {
        height: 40px;
        text-align: right;
    }

    .code-box-count {
        text-align: right;
        height: 40px;
        font-size: 14px;
        line-height: 22px;
        color: #646a73;
    }

    .login-err-tip {
        color: #f54a45;
        font-size: 14px;
        margin-bottom: 16px;
    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;