.supplierInfoCensored{
    // border: black solid 1px;
    margin: 80px auto 0;
    width: 70%;
    min-width: 600px;
    height: 70vh;
    min-height: 300px;
    overflow: auto;

    
    background-color: white;
    box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

    display: flex;
    flex-direction: column;

    .titleLine{
        height: min-content;
        text-align: center;
        margin: 22px auto 12px ;

        .title {
            color: #000;
            font-size: 24px;
            font-family: 'Hiragino Sans GB';
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }
    .attrLine:first{
        border-top: #d10000 solid 1px;
    }
    .attrLine{
        margin: 0 33px;
        padding: 12px 5px;
        border-bottom: #f3f3f3 solid 1px;
    }
    .licenseLine{
        margin: 24px 33px;
        // border: black solid 1px;;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;