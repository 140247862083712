.supplierInfoUncensored {
    overflow: auto;
    height: 90vh;
    // min-height: 300px;

    .titleLine{
        margin: 28px 0;
        span{
            display: block;
            margin: 0 auto;
            text-align: center;
            color: #000;
            font-family: "Hiragino Sans GB", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    
    .supplierInfoPage{
        // border: black solid 1px;
        margin: 0 auto ;
        width: 70%;
        min-width: 600px;
        // background-color: #f6f6f6;
        box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);
    
        display: flex;
        flex-direction: column;
    
        // >*{border:black solid 1px}\

        .disabledModule{
            background-color: #F6F7F9;
        }
        .enabledModule{
            background-color: #fff;
        }
    
        .mainTextLine{
            height: 44px;
    
            margin: 0;
            padding: 7px 52px;
    
            border-radius: 0px 2px 2px 0px;
            background: #ECF3FA;
    
            span{
                color: #000;
                font-family: "Hiragino Sans GB", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            .leftbar{
                width: 2px;
                height: 44px;
                background-color: #1271ff;
    
                position: relative;
                left: -52px;
                top: -33px;
            }
    
        }
        .infoTextLine{
    
            padding: 10px 60px;
    
            span{
                color: #8D8D8D;
                font-family: "Hiragino Sans GB", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
        .inputLine{
            margin: 0 0;
            padding: 24px 60px ;
    
            // background-color: white;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
    
            .inputField{
                width: fit-content;
                min-width: 260px;
                // border: #000 solid 1px;
    
                >*{
                    display: inline-block;
                }
    
                span{
                    
                    color: #8D8D8D;
                    font-family: "Hiragino Sans GB", sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
    
                .input{
                    width: calc(22vw);
                    min-width: 200px;
                }
            }
        }
        .imageField{
            padding: 32px 60px;
            margin: 0 0;
    
            // background-color: white;
            span{
                
                color: #8D8D8D;
                font-family: "Hiragino Sans GB", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
    
            .uploadImage{
                margin: 0 20px;
                // border: black solid 1px;
                // width: 100px;
                // height: 100px;
            }
            .uploadImage:hover{
                cursor: pointer;
            }
            .disabledUploadImage{
                margin: 0 20px;
                // border: rgb(100, 100, 100) solid 1px;
            }
        }
    }
    .submitButtonLine{
        margin-top: 39px;
        // border: #000 solid 1px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .submitButton{
            width: 146px;
            height: 35px;
            flex-shrink: 0;
            background-color: #1271ff;
            border-radius: 6px;
    
            margin: 0 auto;
            display: block;
    
            span{
                color: #FFF;
                font-family: "Hiragino Sans GB", sans-serif;
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;