.login-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    //background: #f0f2f5;
    height: 100%;
    //width: 350px;
    //background: #dddddd;
    padding: 20px;
    border-radius: 6px;
    align-items: center;
    

    .login-form {
        width: 300px;
        &-password {
            float: right;
        }
        &-button {
            width: 100%;
        }
    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;