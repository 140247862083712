.evaluation-scroll{
    overflow: auto;
    height: 100%;
}
.evaluation{
    width: 70%;
    min-width: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    >*{width: 100%;}

    & &-topLine{
        // border: black 1px solid;
        height: 50px;
        // background-color: rgb(209, 209, 209);
        margin: 26px 0;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .titleBox{
            border-left: #1271FF solid 2px;
            padding: 0 15px;
            background-color: #ECF3FA;

            .title{
                color: #000;
                font-family: 'Hiragino Sans GB', sans-serif;
                font-size: 24px;

            }
        }
        .filterBox{
            width: 555px;
            height: 47px;
            border-radius: 4px;
            background: #FFF;
            box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            // padding: 0 45px;

            .filterTxt{
                color: #191515;
                font-family: 'Hiragino Sans GB', sans-serif;
                font-size: 20px;
            }
            .filterItem{
                // border: #000 solid 1px;
                height: 100%;
                vertical-align:bottom;
                

                display: flex;
                flex-direction: row;
                align-items: center;
                
                border-bottom: #1271ff00 solid 2px;

                .countTxt{
                    color: #A9A9A9;
                    font-family: 'Hiragino Sans GB', sans-serif;
                    font-size: 12px;
                    
                }
            }
            .filterItem:hover{
                border-bottom: #1271FF solid 2px;
                cursor: pointer;
            }
            .filterItemChosen{
                border-bottom: #1271FF solid 2px;
            }
        }
        .releaseBox{
            width: 132px;
            height: 47px;

            .release{
                width: 132px;
                height: 47px;
                flex-shrink: 0;
                border-radius: 4px;
                background: #1271FF;
                box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

                span{
                    color: #FFF;
                    font-family: 'Hiragino Sans GB', sans-serif;
                    font-size: 20px;
                    font-weight:600;
                }
            }
        }
    }

    & &-page{
        flex: 1 1 auto;
        margin-bottom: 40px;
        // height: fit-content;
        border-radius: 4px;
        background: #FFF;
        box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

        padding: 32px 46px 16px;

        .evaluationEntry{
            height: 175px;
            border-radius: 4px;
            border: 2px solid rgba(239, 245, 249, 0.70);
            background: #FFF;
            margin: 0 0 12px;

            padding: 15px 25px 15px 35px ;

            .detailLine{
                height: 100px;
                // border: #000 solid 1px;
                margin: 0 0 16px;
                
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                // >*{border: #000 dotted 1px;}

                .avatarBox{
                    // height: 100%;
                    width: 70px;

                    margin-right: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;

                    span{
                        margin-top: 10px;
                        color: #000;
                        font-family: Hiragino Sans GB;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .contentBox{
                    height: 100%;
                    width: 70%;
                    min-width: 300px;

                    margin: 0 10px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;

                    .textLine{
                        overflow: auto; 
                        span{
                            color: #000;
                            font-family: Hiragino Sans GB;
                            font-size: 16px;
                        }
                    }
                }
                .starBox{
                    // border: #000 solid 1px;
                    height: max-content;
                    width: 150px;

                    margin-left: 10px;
                }
                
            }

            .infoLine{
                height: 24px;
                // border: #000 solid 1px;

                display: flex;
                flex-direction: row;
                align-items: baseline;

                >*{
                    margin-right: 25px;
                    span{
                        padding-left: 5px;
                        color: #898989;
                        font-family: Hiragino Sans GB;
                        font-size: 12px;
                        border-left: #D9D9D9 solid 2px;
                    }
                }
            }
        }


    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;