.forget-pwd-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    
    height: 100%;
    padding: 20px;
    border-radius: 6px;
    align-items: center;


    .submit-group {
        display: flex;
        justify-content: space-between;
    }

    .submit-button {
        min-width: 100px;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;