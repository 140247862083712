.steel-detail {
    flex-wrap: 1;
    height: 620px;
    .title {
        text-align: center;
        margin-bottom: 16px;
        color: #000;
        font-family: Hiragino Sans GB;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }
    .imageBox {
        align-items: center;
        margin-left: 10px;
        width: 100px;
        height: 85px;
        margin: auto 25px;

    }

    .steel-text-con {
        margin-top: 20px;

    }

    .steel-text {
        margin-top: 3px;
        font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
        margin-left: 10px;
        font-size: 20px;
    }

}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;