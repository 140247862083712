// new ui
.coating{
    // border: black 1px solid;
    width: 70%;
    min-width: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;

    >*{width: 100%;}
    
    & &-page{
        height: max-content;
        overflow: auto;
        // height: fit-content;
        border-radius: 4px;
        background: #FFF;
        box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

        padding: 18px 46px 16px;
        margin-bottom: 40px;

        .coatingEntry{
            height: 105px;
            border: black 1px solid;
            margin: 0 0 12px;

            border-radius: 4px;
            border: 2px solid rgba(239, 245, 249, 0.70);
            background: #FFF;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .imageBox{
                width: 81px;
                height: 75px;

                margin: auto 25px;
            }
            .rightBox{

                flex-grow: 1;
                margin-right: 16px;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .infoLine{

                    .xxx:hover{
                        cursor: pointer;
                    }
                    height: 24px;
                    width: 100%;
                    margin: 5px 0;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    span{
                        color: #000;
                        font-family: Hiragino Sans GB;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                    }
                }
                .infoLine2{
                    height: 24px;
                    width: 100%;
                    margin: 5px 0;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;

                    span{
                        color: #000;
                        font-family: Hiragino Sans GB;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        
                        margin-right: 12px;
                    }
                }
                .priceLine{
                    height: 32px;
                    width: 100%;


                    span{
                        float: right;
                        color: #000;
                        font-family: Hiragino Sans GB;
                        font-size: 20px;
                    }
                }
            }

        }
    }
}


.goods-coating {
    height: 100%;
    display: flex;
    flex-direction: column;

    .tool {
        height: 35px;
    }

    .list {
        flex: 1 1 auto;

        // antd
        .ant-table-wrapper {
            height: 100%;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }
}

.add-coating-dm-container {
    padding: 5px;
    .title{
        margin-bottom: 10px;

        text-align: center;
        margin-bottom: 16px;
        color: #000;
        font-family: Hiragino Sans GB;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }

    .form{
        margin: 0 15px;
        padding: 0;
        // background-color: antiquewhite;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;

        Button{
            display: block;
            width: 146px;
            height: 35px;
            flex-shrink: 0;
            border-radius: 6px;

            span{
                display: block;
                margin-top: -5px;
                font-family: Hiragino Sans GB;
                font-size: 22px;
            }
        }
    }

    // antd
    .col1 .ant-form-item-label {
        width: 100px;
    }
    .col2 .ant-form-item-label {
         width: 120px;
     }
}

.cm-container {
    background-color: white;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;
    padding: 5px;
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;