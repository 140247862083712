.user-dropdown-container {

    .user-dropdown {

        display: flex;
        flex-direction: row;
        align-items: center;

        .avatar {
            width: 32px;
            height: 32px;

            margin-right: 8px;
            background-color: #ffffff;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .name {
            display: flex;
            flex-direction: column;

            .mobile {
                font-size: 14px;
                font-weight: bold;
                margin-right: 10px;
            }

            .typessss {
                color: "#1271ff";
                background-color: "#d3e5ff";
            }
        }
    }

    .ant-image {
        display: flex;
        align-items: center;
    }
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;