.topbarBox {
    width: 100%;
    height: 60px;


    background-color: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);


    position: fixed;
    top:0;
    left: 0;

    display: flex;
    flex-direction: row;
    flex: 1;
    align-items:center;

    justify-content: space-between;


    z-index: 10;

    >div {
        // border: black dotted 1px;
        width: fit-content;
        display: flex;
        flex-direction: row;
    }
    span{
        cursor:default;
    }

    .logoBox{
       
       display: flex;
       align-items: center;
       text-align: center;
       margin-left: 30px;
        // border: black dotted 1px;
        .logo{
            // width: 60px;
            // height: 55px;
            flex-shrink: 0;

            // box-shadow: 0px 4px 4px 0px #0069B2;
        }
    }
    .titleBox{
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 5px;

        // border: black dotted 1px;
        .title{
            color: #484848;
            font-size: 48px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -2.4px;
            cursor:pointer;
        }
    }
    .listBox{
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 17px;
        
        .image:hover{
            cursor: pointer;
        }
    }
    .tabBox{
        width: 40px+88px;
        text-align: center;

        .tabTxt{
            color: #A4ADB2;
            font-size: 20px;
            font-family: 'Hiragino Sans GB';
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .chooseBar{
            width: 50px;
            height: 3px;
            background-color: #1271FF;

            position: relative;

            top: 16px;
            left: 40px;
            visibility: hidden;
        }

    }
    .tabBox:hover{

        .tabTxt{
           color: #1271FF;
           cursor:pointer;
        }
        
        .chooseBar{
            visibility: visible;
        }
    }
    
    .tabBoxSelecting{
        .tabTxt{
            color: #1271FF;
         }

         .chooseBar{
            visibility:visible;
        }
    }
    .userDropDown{
        margin-right: 33px;
    }

    .avatarBox{
        // margin-left: calc(565px - 44px);
        width: 38px;

        .avatar{
            width: 38px;
            height: 38px;
            flex-shrink: 0;
            z-index: 2;

            .avatarTxt{
                color: #FFF;
                font-size: 20px;
                font-family: 'Hiragino Sans GB';
                font-style: normal;
                font-weight: 300;
                line-height: normal;

                z-index: 99999;
            }
        }
    }
    .supplierNameBox{
        margin-left: 12px;
        width: auto;

        .supplierName{
            color: #060606;
            font-size: 16px;
            font-family: Hiragino Sans GB;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .supplierName2{
            width: 24px;
            height: 18px;
            background-color: #D3E5FF;
        }

    }
    .arrowdownBox{
        margin-left: 30px;
        margin-right: 20px;
        width: 20px;

    }
    
    // * {border: black dotted 1px;}
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;