.detail-container {
    background: #F6F6F6;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    height: 100% ;
    display: flex;
    //justify-content: center;
    background-color: #f5f5f5;
    // overflow-x: hidden;
    // overflow-y: scroll;
    overflow: auto;
    
    .header {
        display: flex;
        width: 100%;
        height: 60px;
        background-color: '#ffffff';
        flex-direction: row;
        padding: 3.5px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);

        &-image {
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 26.5px;
        }

        &-title {
            display: flex;
            align-items: center;
            text-align: center;
            margin-left: 5px;
        }

    }

    .list-container {
        align-items: center;
        width: 1366px;
        height: 1053px;

        //text-align: center;
    }

}

@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;