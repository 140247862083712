.login-container {
    margin-top: 100px;
    // background: #dddddd;
    padding: 20px;
    border-radius: 6px;
    align-items: center;

    .login-form {
        width: 300px;
        &-code {
            float: right;
        }
        &-forgot {
            float: left;
        }
        &-button {
            width: 100%;
        }
    }

}

@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;