.goods-steel {

    width: 70%;
    min-width: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 0 auto;
    padding-bottom: 40px;

    >* {
        width: 100%;
    }

    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;

    .steel-list {
        background-color: white;

        height: max-content;
        //overflow: auto;
        // height: fit-content;
        border-radius: 4px;
        background: #FFF;
        box-shadow: -4px 4px 4px 0px rgba(122, 171, 204, 0.05);

        padding: 18px 46px 16px;
        margin-bottom: 40px;
        //margin-top: 18px;

        .steelEntry {
            height: 105px;
            border: black 1px solid;
            margin: 0 0 12px;

            border-radius: 4px;
            border: 2px solid rgba(239, 245, 249, 0.70);
            background: #FFF;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .imageBox {
                width: 81px;
                height: 75px;

                margin: auto 25px;
            }

            .rightBox {

                flex-grow: 1;
                margin-right: 16px;
                height: 100%;

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .infoLine {

                    .xxx:hover {
                        cursor: pointer;
                    }

                    height: 24px;
                    width: 100%;
                    margin: 5px 0;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    //margin-top: 35px;
                    .infoLine-left {
                        margin-top: 57px;

                        span {
                            color: #000;
                            font-family: Hiragino Sans GB;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 300;

                            .item-sectionName {
                                margin-top: 5px;
                                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
                                margin-left: 10px;
                                font-size: 16px;
                            }

                            .item-matGradeName {
                                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
                                margin-left: 10px;
                                font-size: 16px;
                            }

                            .item-add {
                                font-family: tahoma, arial, 'Hiragino Sans GB', '000000', sans-serif;
                                margin-left: 10px;
                                margin-top: 10px;
                                font-size: 14px;
                            }

                        }
                    }


                }

                .priceLine {
                    height: 32px;
                    width: 100%;


                    span {
                        float: right;
                        color: #000;
                        font-family: Hiragino Sans GB;
                        font-size: 20px;
                    }
                }
            }

        }
    }

}

.add-steel-dm-container {
    flex-shrink: 0;
    width: 800px;
    height: 620px;



    .title {
        text-align: center;
        margin-bottom: 16px;
        color: #000;
        font-family: Hiragino Sans GB;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
    }

    .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        Button {
            display: block;
            width: 146px;
            height: 35px;
            flex-shrink: 0;
            border-radius: 6px;
            margin-right: 420px;

            span {
                display: block;
                margin-top: -5px;
                font-family: Hiragino Sans GB;
                font-size: 22px;
            }
        }
    }

    // antd
    .ant-divider {
        margin: 10px 30px;
    }

    .ant-form-item-label {
        width: 70px;
    }

    .ant-form {
        margin: 0 auto;
        width: 710px;
        // background-color: #1271FF;
        margin: auto;
    }
}

.cm-container {
    background-color: white;
    border-radius: 2px;
    box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px;
    padding: 5px;
}
@primary-color: #0283b5;@text-color: #1f2e3d;@success-color: #52a360;